<template>
  <div id="app" :class="theme">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    theme () {
      return this.$store.state.theme
    },
  },
  mounted () {
    const savedTheme = localStorage.getItem('userTheme')
    if (savedTheme) this.$store.commit('setTheme', savedTheme)
  },
}
</script>

<style lang="scss" src="./scss/app.scss"></style>
