import { createStore } from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '@/firebase'
import router from '@/router'
import parser from '@clongdela/parser'

function defaultEditorState () {
  return {
    document: null,
    project: null,
    code: '',
    tag: null,
    error: null,
  }
}

function refreshTag (state, tag) {
  const newTag = { year: null, language: null }
  if (!tag) return newTag
  newTag.year = tag.year
  const languages = state.editor.project.languages
  if (tag.language && languages.has(tag.language.identifier)) {
    newTag.language = languages.get(tag.language.identifier)
  } else if (languages.size > 0) {
    newTag.language = Array.from(languages.values())[0]
    const milestones = newTag.language.milestones
    if (milestones.has(tag.year)) {
      newTag.year = tag.year
    } else {
      newTag.year = Array.from(milestones.values())[0]
    }
  }

  return newTag
}

export default createStore({
  state: {
    user: null,
    userDoc: {},
    editor: defaultEditorState(),
    theme: '',
  },
  mutations: {
    ...vuexfireMutations,
    resetEditor (state) {
      state.editor = defaultEditorState()
    },
    setCode (state, code) {
      state.editor.code = code
    },
    setDocument (state, document) {
      if (state.editor.document.code !== document.code || state.editor.project === null) {
        try {
          const project = parser.parse(document.code ?? '')
          state.editor.project = project
          const tag = refreshTag(state, state.editor.tag)
          state.editor.tag = tag
        } catch (error) {
          state.editor.error = error
          if (!(error instanceof parser.SyntaxError)) console.error(error)
        }
      }
      state.editor.document = document
      state.editor.code = document.code
    },
    setTag (state, tag) {
      const newTag = refreshTag(state, tag)
      state.editor.tag = newTag
    },
    login (state, user) {
      state.user = user
    },
    logout (state) {
      state.userDoc = null
      state.user = null
      if (router.currentRoute.value.matched.some(record => record.meta.requiresAuth)) {
        router.push('/')
      }
    },
    setTheme (state, theme) {
      state.theme = theme
      localStorage.setItem('userTheme', theme)
    },
  },
  actions: {
    login: firestoreAction(({ bindFirestoreRef, commit }, user) => {
      const uid = user.uid
      const docRef = db.collection('users').doc(uid)
      commit('login', user)
      return bindFirestoreRef('userDoc', docRef)
    }),
    bindDocument: firestoreAction(({ bindFirestoreRef }, id) => {
      const docRef = db.collection('projects').doc(id)
      return bindFirestoreRef('editor.document', docRef)
    }),
    unbindDocument: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('editor.document')
    }),
    updateDocument: firestoreAction(({ state, commit }, updated) => {
      const document = state.editor.document
      updated = Object.assign({ id: document.id }, document, updated)
      const docRef = db.collection('projects').doc(document.id)
      commit('setDocument', updated)
      return docRef.update(updated)
    }),
  },
})
