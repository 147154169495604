<template>
  <router-link to="/future" target="_blank" class="future-warning" :class="{ disabled }">
    FUTURE OF CONLANG TOOLS
    <font-awesome-icon icon="exclamation-triangle" v-if="!disabled" />
  </router-link>
</template>

<script>
export default {
  name: 'FutureWarning',
  computed: {
    disabled () {
      return this.$route.name === 'Future'
    },
  },
}
</script>

<style lang="scss" scoped>
a.future-warning {
  padding: 0.5em;
  font-weight: 800;
  color: var(--c-03) !important;

  svg {
    margin-left: 0.5rem;
    position: relative;
    top: 0px;
  }

  &.disabled {
    pointer-events: none;
    cursor: pointer;
    color: var(--c-txt-secondary) !important;
  }
}
</style>
