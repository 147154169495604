<template>
  <nav class="navbar">
    <router-link to="/"><h1 class="brand" v-if="showBrand">Conlang Workbench</h1></router-link>
    <ul class="nav-items">
      <li class="nav-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/docs">Docs</router-link>
      </li>
      <li class="nav-item" v-if="$store.state.user !== null">
        <router-link to="/projects">My Projects</router-link>
      </li>
      <li class="nav-item">
        <future-warning />
      </li>
      <li class="nav-item">
        <user-controls />
      </li>
    </ul>
  </nav>
</template>

<script>
import UserControls from '@/components/UserControls.vue'
import FutureWarning from '@/components/FutureWarning.vue'

export default {
  name: 'Navbar',
  components: { UserControls, FutureWarning },
  props: { showBrand: Boolean },
}
</script>

<style lang="scss">
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;

  .brand {
    margin-left: 1rem;
    font-weight: 300;
    font-size: 20px;
    color: var(--c-highlight);

    &:hover {
      filter: brightness(120%);
    }
  }

  .nav-items {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    list-style: none;
    gap: 0.5rem;
    padding-right: 1rem;

    .nav-item {
      a {
        text-decoration: none;
        padding: 1rem;
        color: inherit;
        display: block;
        height: 100%;
        transition: background-color 0.1s ease-in-out,
                               color 0.1s ease-in-out;

        &:not(.router-link-exact-active):hover {
          color: var(--c-txt-emphasis);
          background: var(--c-bg-shade);
        }

        &.router-link-exact-active {
          color: var(--c-highlight);
        }
      }

      .user-controls {
        padding: 0;
        height: 100%;

         & > button {
          display: block;
          height: 100%;
        }
      }
    }
  }
}
</style>
