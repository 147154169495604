<template>
  <div class="user-controls">

    <template class="user" v-if="user !== null">
      <button @focus="showDropdown = true" @blur="showDropdown = false">
        {{ user.displayName }}
        <ul class="user-options" :style="{ display: showDropdown ? 'block' : 'none' }" @mousedown.prevent>
          <li>
            <router-link class="btn" to="/projects">
              <font-awesome-icon icon="folder-open"/>
              My Projects
            </router-link>
          </li>
          <li>
            <theme-picker />
          </li>
          <li>
            <button @click="signout">
              <font-awesome-icon icon="sign-out-alt" class="signout-icon" />
              Sign Out
            </button>
          </li>
        </ul>
      </button>
    </template>

    <template v-else>
      <button @click="showModal = true">Login / Sign-up</button>
    </template>

    <signin-modal v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import SigninModal from '@/components/SigninModal.vue'
import ThemePicker from '@/components/ThemePicker.vue'
import { auth } from '@/firebase'

export default {
  name: 'UserControls',
  components: { SigninModal, ThemePicker },
  data () {
    return {
      showModal: false,
      showDropdown: false,
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
  },
  methods: {
    signout () {
      auth.signOut()
        .catch(error => {
          console.error(error)
          alert(error.message)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-controls {
  position: relative;

  button, a.btn {
    border: none;
    border-radius: 0;
    padding: 0.5rem;
    transition: color 0.1s ease-in-out,
      background-color 0.1s ease-in-out;
    width: 100%;

    &:hover,
    &[type="menu"]:focus {
      background: var(--c-bg-shade);
      color: var(--c-txt-emphasis);
    }

    &:focus::before {
      display: none;
    }
  }

  .user-options {
    list-style: none;
    border: 2px solid var(--c-border);
    position: absolute;
    right: 0;
    top: calc(100% + 0.5rem);
    z-index: 100;
    background: var(--c-bg);

    button, a.btn {
      font-weight: 400;
      white-space: nowrap;
      color: var(--c-txt-emphasis);
      text-align: left;
      padding: 0.5rem;

      svg {
        margin: 0 0.5rem;

        &.signout-icon {
          color: var(--c-error);
        }
      }
    }
  }
}
</style>
