<template>
  <button
    class="theme-button"
    @click="setTheme"
    :style="style"
    :class="{ active: theme === currentTheme }"
    :aria-label="name"
  >
  </button>
</template>

<script>
export default {
  name: 'ThemeButton',
  props: {
    theme: String,
    name: String,
  },
  computed: {
    currentTheme () {
      return this.$store.state.theme
    },
    style () {
      return { background: `var(--c-theme-${this.theme})` }
    },
  },
  methods: {
    setTheme () {
      this.$store.commit('setTheme', this.theme)
    },
  },
}
</script>

<style lang="scss" scoped>
.theme-button {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--c-border);
  border-radius: 50%;

  &:hover {
    border-color: var(--c-highlight);
  }

  &.active {
    border-color: var(--c-success);
  }
}
</style>
