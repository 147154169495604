<template>
  <font-awesome-icon icon="spinner" class="spinner" />
</template>

<style lang="scss">
@keyframes spinner {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}

.spinner {
  animation: 1s linear infinite spinner;
}
</style>
