<template>
  <div class="theme-picker">
    <h3>Select Theme:</h3>
    <ul class="themes">
      <li><theme-button theme="solarized-dark" name="Solarized Dark" /></li>
      <li><theme-button theme="solarized-light" name="Solarized Light" /></li>
      <li><theme-button theme="gunmetal" name="Gunmetal (Dark)" /></li>
      <li><theme-button theme="open-dark" name="Open Dark" /></li>
      <li><theme-button theme="open-light" name="Open Light" /></li>
    </ul>
  </div>
</template>

<script>
import ThemeButton from '@/components/ThemeButton.vue'

export default {
  name: 'ThemePicker',
  components: { ThemeButton },
}
</script>

<style lang="scss" scoped>
.theme-picker {
  color: var(--c-txt-primary);
  padding: 0.5rem;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }

  .themes {
    display: flex;
    gap: 0.5rem;
    list-style: none;
  }
}
</style>
