<template>
  <aside class="signin-modal flex-container" @click.self.stop="onClick">
    <div class="content" ref="modalBody">
      <div class="header">
        <h2>{{ signup ? 'Sign Up' : 'Sign In' }}</h2>
        <button class="close icon" @click="$emit('close')">
          <font-awesome-icon icon="times" />
        </button>
        <p class="hint">Log-in or sign-up now to save and share your work.</p>
        <template v-if="signup">
          Already have an account? <button type="button" class="toggle-signup" @click="signup = false">Log in instead.</button>
        </template>
        <template v-else>
          New user? <button type="button" class="toggle-signup" @click="signup = true">Sign up instead.</button>
        </template>
      </div>
      <div class="body">
        <form id="signin-form" novalidate="true" @submit.prevent="submitForm">
          <div class="input-group" v-if="signup">
            <label for="name">Display Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              v-model.trim="formData.name"
              @change="validate('name')"
              :class="{ error: errors.name?.any }"
            />
            <ul class="errors" v-if="errors.name?.any">
              <li v-if="errors.name.required">This is a required field.</li>
              <li v-if="errors.name.minLength">Your username must be at least 3 characters long.</li>
              <li v-if="errors.name.alphaNum">Your username may contain only alphanumeric characters: A-Z, a-z, 0-9.</li>
            </ul>
          </div>
          <div class="input-group">
            <label for="email">Email Address:</label>
            <input
              type="text"
              name="email"
              id="email"
              v-model.trim="formData.email"
              @change="validate('email')"
              :class="{ error: errors.email?.any }"
            />
            <ul class="errors" v-if="errors.email?.any">
              <li v-if="errors.email.required">This is a required field.</li>
              <li v-if="errors.email.email">You must enter a valid email address.</li>
            </ul>
          </div>
          <div class="input-group">
            <label for="password">Password: <span class="caps-hint" v-if="capsLock">Caps Lock is ON.</span></label>
            <input
              :type="showPassword ? 'text' : 'password'"
              name="password"
              id="password"
              v-model="formData.password"
              @change="validate('password')"
              :class="{ error: errors.password?.any }"
            />
            <button type="button" @click="showPassword = !showPassword">
              <font-awesome-icon icon="eye-slash" v-if="showPassword" />
              <font-awesome-icon icon="eye" v-else />
            </button>
            <ul class="errors" v-if="errors.password?.any">
              <li v-if="errors.password.required">This is a required field.</li>
              <li v-if="errors.password.minLength">Your password should be at least 8 characters long.</li>
              <li v-if="errors.password.uppercase">Your password must contain at least one uppercase letter (A-Z).</li>
              <li v-if="errors.password.lowercase">Your password must contain at least one lowercase letter (a-z).</li>
              <li v-if="errors.password.digit">Your password must contain at least one digit (0-9).</li>
              <li v-if="errors.password.special">Your password must contain at least one special character (! " £ $ % ^ &amp; * _ + = @ ; ' , . ? -).</li>
            </ul>
          </div>
          <div class="input-group" v-if="signup">
            <label for="comfirmPassword">Confirm Password:</label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              v-model="formData.confirmPassword"
              @change="validate('confirmPassword')"
              :class="{ error: errors.confirmPassword?.any }"
            />
            <ul class="errors" v-if="errors.confirmPassword?.any">
              <li v-if="errors.confirmPassword.required">This is a required field.</li>
              <li v-if="errors.confirmPassword.match">You must enter the same password again.</li>
            </ul>
          </div>
        </form>
        <div class="alert error" v-if="submitError">
          <b>Error: </b>{{ submitError.message }}
        </div>
      </div>
      <div class="footer">
        <button type="button" class="primary" v-if="!signup" @click="signup = true">Sign Up</button>
        <button type="submit" form="signin-form" class="secondary" :disabled="loading">{{ signup ? 'Sign Up' : 'Login' }} <spinner v-if="loading" /></button>
      </div>
    </div>
  </aside>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import { createUser, loginUser } from '@/firebase/auth'

const email = value =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(value)

export default {
  name: 'SigninModal',
  components: { Spinner },
  data () {
    return {
      signup: false,
      showPassword: false,
      capsLock: false,
      formData: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      valid: false,
      errors: {},
      validations: {
        name: {
          required: () => this.signup,
          minLength: value => value.length >= 3,
          alphaNum: value => /^[A-Za-z0-9]+$/.test(value),
        },
        email: {
          required: () => true,
          email,
        },
        password: {
          required: () => this.signup,
          minLength: value => value.length >= 8,
          uppercase: value => /[A-Z]/.test(value),
          lowercase: value => /[a-z]/.test(value),
          digit: value => /\d/.test(value),
          special: value => /[!"£$%^&*()_+={}[\]:@;',.<>?/|\\-]/.test(value),
        },
        confirmPassword: {
          required: () => this.signup,
          match: value => value === this.formData.password,
        },
      },
      submitError: null,
      loading: false,
    }
  },
  methods: {
    checkCapsLock (e) {
      if (e.getModifierState) this.capsLock = e.getModifierState('CapsLock')
    },
    onClick (e) {
      if (!this.$refs.modalBody.contains(e.target)) {
        this.$emit('close')
      }
    },
    validate (field) {
      const validations = Object.entries(this.validations[field])
      const value = this.formData[field]
      let errors = {
        any: false,
      }

      for (const [name, validator] of validations) {
        if (name === 'required') {
          const required = validator(value)
          if (required) {
            if (value === '') {
              errors[name] = true
              errors.any = true
              break
            }
          } else {
            errors = { any: false }
            break
          }
        } else {
          const valid = validator(value)
          if (!valid) {
            errors[name] = true
            errors.any = true
          }
        }
      }

      this.errors[field] = errors
      this.valid = !Object.values(this.errors)
        .some(errors => errors.any)
    },
    submitForm () {
      const fields = ['email', 'password']
      if (this.signup) fields.push('name', 'confirmPassword')
      for (const field of fields) {
        this.validate(field)
      }
      if (this.valid) {
        this.loading = true
        if (this.signup) {
          createUser(this.formData)
            .then(user => {
              this.submitError = null
              this.loading = false
              this.$emit('close')
            })
            .catch(error => {
              console.error(error)
              this.loading = false
              this.submitError = error
            })
        } else {
          loginUser(this.formData)
            .then(user => {
              this.submitError = null
              this.loading = false
              this.$emit('close')
            })
            .catch(error => {
              console.error(error)
              this.loading = false
              this.submitError = error
            })
        }
      }
    },
  },
  mounted () {
    document.addEventListener('keyup', this.checkCapsLock)
    document.addEventListener('mousedown', this.checkCapsLock)
    // setTimeout(function (self) {
    //   document.addEventListener('click', self.onClick)
    // }, 10, this)
  },
  unmounted () {
    document.removeEventListener('keyup', this.checkCapsLock)
    document.removeEventListener('mousedown', this.checkCapsLock)
    // document.removeEventListener('click', this.onClick)
  },
}
</script>

<style lang="scss" scoped>
.signin-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  padding: 2rem;

  background: var(--c-bg-tint);

  .content {
    background: var(--c-bg-shade);
    border: 2px solid var(--c-border);
    height: 100%;
    white-space: normal;

    @include md {
      margin-top: 20vh;
      height: auto;
      width: 40vw;
    }

    .header {
      position: relative;

      .close {
        position: absolute;
        top: 0;
        right: 0;
      }

      .hint {
        margin-top: 0.5rem;
        font-weight: 300;
        font-size: 20px;
        color: var(--c-warn);
      }

      .toggle-signup {
        padding: 0;
        margin: 0;
        margin-top: 0.5rem;
        color: var(--c-highlight);
        border: none;
        background: transparent;

        &:hover {
          opacity: 0.7;
          text-decoration: underline;
        }
      }
    }

    .body {
      padding: 1rem 0;

      input.error {
        border-color: var(--c-error);
      }

      .caps-hint {
        color: var(--c-error);
        font-weight: 400;
        font-size: 14px;
        float: right;
      }

      #password {
        display: inline-block;
        width: calc(100% - 2rem);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        & + button {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          border-width: 1px;
          border-color: var(--c-border);
          margin: 0;
          padding: 0.25rem;
          font-size: 16px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }

      .errors {
        color: var(--c-error);
        font-size: 14px;
        margin-left: 2rem;
        margin-top: 0.5rem;
      }

      .alert {
        margin-top: 1rem;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }
}
</style>
