import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import { auth } from '@/firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      title: 'Projects | Conlang Tools',
      requiresAuth: true,
    },
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: () => import(/* webpackChunkName: "edit" */ '../views/Editor.vue'),
    meta: {
      title: 'Editor | Conlang Tools',
      requiresAuth: true,
    },
    children: [
      {
        path: 'code',
        name: 'code',
        component: () => import(/* webpackChunkName: "edit-code" */ '../views/editor_panes/CodePane.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'details',
        name: 'details',
        component: () => import(/* webpackChunkName: "edit-details" */ '../views/editor_panes/DetailsPane.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'lexicon',
        name: 'lexicon',
        component: () => import(/* webpackChunkName: "edit-lexicon" */ '../views/editor_panes/LexiconPane.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'tree',
        name: 'tree',
        component: () => import(/* webpackChunkName: "edit-tree" */ '../views/editor_panes/TreePane.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/future',
    name: 'Future',
    component: () => import(/* webpackChunkName: "beta-info" */ '../views/Future.vue'),
    meta: {
      title: 'Future of Conlang Tools | Conlang Tools',
    },
  },
  {
    path: '/docs',
    name: 'Docs',
    component: () => import(/* webpackChunkName: "docs" */ '../views/Docs.vue'),
    children: [
      {
        path: '',
        name: 'Introduction',
        component: () => import(/* webpackChunkName: "docs-introduction" */ '../views/docs_articles/Introduction.vue'),
        meta: {
          title: 'Docs | Conlang Tools',
        },
      },
      {
        path: 'getting-started',
        name: 'GettingStarted',
        component: () => import(/* webpackChunkName: "docs-getting-started" */ '../views/docs_articles/GettingStarted.vue'),
        meta: {
          title: 'Getting Started | Docs | Conlang Tools',
        },
      },
    ],
  },
  {
    path: '/docs/cheat-sheet',
    name: 'CheatSheet',
    component: () => import(/* webpackChunkName: "cheat-sheet" */ '../views/CheatSheet.vue'),
    meta: {
      title: 'Cheat Sheet | Docs | Conlang Tools',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    meta: {
      title: '404 Not Found | Conlang Tools',
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) return { selector: to.hash, behavior: 'smooth' }
    if (savedPosition) return savedPosition
    return { left: 0, top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (from.matched.length === 0) return next()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth.currentUser === null) {
      return next('/')
    }
  }
  return next()
})

const siteDefaultName = 'Conlang Tools'
router.afterEach(to => {
  nextTick(() => {
    const title = to.meta.title
    document.title = title ? `${title}` : siteDefaultName
  })
})

export default router
