import store from '@/store'

const firebase = window.firebase

const auth = firebase.auth()
auth.onAuthStateChanged(user => {
  if (user) store.dispatch('login', user)
  else store.commit('logout')
})

const db = firebase.firestore()

export {
  firebase,
  auth,
  db,
}
