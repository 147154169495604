import { auth } from '.'

async function createUser (data) {
  const { email, password, name } = data
  const { user } = await auth.createUserWithEmailAndPassword(email, password)
  await user.updateProfile({ displayName: name })
  return user
}

async function loginUser (data) {
  const { email, password } = data
  const { user } = await auth.signInWithEmailAndPassword(email, password)
  return user
}

async function signoutUser () {
  return auth.signout()
}

async function setUserDetails (user, data) {
  return user.updateProfile(data)
}

export {
  createUser,
  loginUser,
  signoutUser,
  setUserDetails,
}
