<template>
  <div class="view home">
    <navbar/>
    <main class="flex-container">
      <div class="content center">
        <h1>Conlang Workbench</h1>
        <br/>
        <p class="lead">Conlang Workbench is a smart, all-in-one conlanging tool with a focus on naturalistic language creation and evolution.</p>
        <br/>
        <div class="alert warning">This site is currently in a public beta testing period. Click <b><router-link to="/beta">here</router-link></b> for more information.</div>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'

export default {
  name: 'Home',
  components: { Navbar },
}
</script>

<style lang="scss" scoped></style>
