import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBook,
  faCode,
  faColumns,
  faDownload,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFolderOpen,
  faInfoCircle,
  faPlusSquare,
  faQuestionCircle,
  faSave,
  faSignOutAlt,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faTimes,
  faTrashAlt,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBook,
  faCode,
  faColumns,
  faDownload,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFolderOpen,
  faInfoCircle,
  faPlusSquare,
  faQuestionCircle,
  faSave,
  faSignOutAlt,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faTimes,
  faTrashAlt,
  faVolumeUp,
)
